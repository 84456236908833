@import url('https://fonts.googleapis.com/css2?family=Product+Sans:wght@400;700&display=swap');

* {
    margin: 0;
}

footer {
    /*position: absolute;*/
    /*width: 100%;*/
    /*height: 100%;*/
    display: flex;
    flex-flow: column nowrap;
    margin: 0;
    /*min-height: 100vh;*/
}

body {
    background-color: #FFFFFF;
    margin: 0;
    overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 768px) {
  footer {
    padding: 10px; /* Added padding for mobile */
    text-align: center; /* Center text for smaller screens */
  }
}
